import classNames from 'classnames/bind';
import { DownloadButton } from 'components/shared/download-button';
import { Heading } from 'components/shared/heading';
import { HeroFrame } from 'components/shared/hero-frame';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import React from 'react';

import styles from './solution-sheet-hero.module.scss';
import Bg from './svg/bg.inline.svg';

const cx = classNames.bind(styles);

const downloadPDFClickHandler = () => {
  trackCustomEvent({ label: 'main', action: 'Click', category: 'SolutionSheetPDFDownload' });
};

const downloadExecutiveSummaryPDFClickHandler = () => {
  trackCustomEvent({ label: 'main', action: 'Click', category: 'SolutionSheetExecutiveSummaryPDFDownload' });
};

export default ({ title, downloadLink, executiveSummaryLink }) => (
  <section className={styles.wrapper}>
    <div className={cx('bg')}>
      <Bg />
    </div>
    <div className={`container ${styles.inner}`}>
      <div className="row">
        <div className="col-lg-6 col-12">
          <span className={styles.kicker}>Executive Solution Sheet</span>
          <Heading className={styles.title}>{title}</Heading>
          <div className={styles.buttons}>
            <DownloadButton
              className={styles.button}
              title={'Solution Sheet.pdf'}
              downloadLink={downloadLink}
              download={'Deliver Fast and Reliable Digital Experiences with k6 - Solution Sheet'}
              onClick={downloadPDFClickHandler}
            />
            {executiveSummaryLink && (
              <DownloadButton
                title={'Executive Summary.pdf'}
                downloadLink={executiveSummaryLink}
                download={'Deliver Fast and Reliable Digital Experiences with k6 - Executive Summary'}
                onClick={downloadExecutiveSummaryPDFClickHandler}
              />
            )}
          </div>
        </div>
      </div>
    </div>
    <HeroFrame />
  </section>
);
