import { SEO } from 'components/blocks/seo';
import Mdx from 'components/containers/mdx';
/* pages */
import styles from 'components/pages/our-beliefs/our-beliefs.module.scss';
import Hero from 'components/pages/solution-sheet/hero';
import { PageInfo } from 'components/shared/page-info';
/* shared */
import { Trait } from 'components/shared/trait';
/* deps */
import { graphql } from 'gatsby';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
/* utils */
import SEO_DATA from 'utils/seo-metadata';

const componentsForNativeReplacement = {
  Trait,
};

const DeliverWithk6 = ({ data: { file } }) => {
  const { body: content, headings } = file.children[0];

  return (
    <DefaultLayout>
      <PageInfo title={'Why Your Organization Should Perform Load Testing'} className={styles.pageInfo} withBgPattern />
      <Mdx
        mode="withToc"
        content={content}
        componentsForNativeReplacement={componentsForNativeReplacement}
        className={styles.contentWrapper}
        headings={headings}
      />
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    file(name: { eq: "deliver-fast-and-reliable-digital-experiences-with-k6" }) {
      children {
        ... on Mdx {
          body
          headings {
            depth
            value
          }
        }
      }
    }
  }
`;

export default DeliverWithk6;

export const Head = () => <SEO {...SEO_DATA['solution-sheet']} />;
