import classNames from 'classnames';
import React from 'react';

import styles from './trait.module.scss';

export const Trait = ({ disadvantage, className = '', children }) => (
  <div
    className={classNames(styles.wrapper, className, {
      [styles.disadvantage]: disadvantage,
    })}
  >
    {children}
  </div>
);
