import classNames from 'classnames/bind';
import React from 'react';

import styles from './download-button.module.scss';
import DownloadIcon from './svg/download.inline.svg';

const cx = classNames.bind(styles);

export const DownloadButton = (props) => {
  const { className = '', title, downloadLink, download, ...rest } = props;
  return (
    <a href={downloadLink} download={download} className={cx('wrapper', className)} {...rest}>
      <DownloadIcon />
      <span className={cx('title')}>{title}</span>
    </a>
  );
};
